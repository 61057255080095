import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLink, Nav } from '@fluentui/react';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';

interface IAccountAdminMenu extends IWithErrorHandlingProps {
    onLinkClicked?: () => void;
}

export const AccountAdminMenu = ({ errorHandling: { clearErrors }, onLinkClicked }: IAccountAdminMenu): React.ReactElement => {
    const { userPermissions: up, orbConfig } = useContext(OrbUserContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const [navs, setNavs] = useState<INavLink[]>([]);
    const [organisationExpanded, setOrganisationExpanded] = useState(false);
    const [manageusersExpanded, setManageUsersExpanded] = useState(false);
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const resetErrors = useCallback(clearErrors, [clearErrors]);

    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
            { name: 'Welcome', path: '/admin/welcome' },
        ].map(NamePathToNavLink);

        if (up.UserIsSystemAdmin()) {
            const organisationLinks = [
                { name: orbConfig.OrgL1LabelP, path: '/admin/groups' },
                { name: orbConfig.OrgL2LabelP, path: '/admin/directorates' },
                { name: orbConfig.OrgL3LabelP, path: '/admin/divisions' }
            ].map(NamePathToNavLink);

            const manageUsersLinks = [
                { name: 'Users', path: '/admin/users' },
                //{ name: 'User groups', path: '/admin/user-groups' },
                { name: 'User Permissions', path: '/admin/administrators' }
            ].map(NamePathToNavLink);

            const settingsLinks = [
                { name: 'Benefit types', path: '/admin/benefit-types' },
                { name: 'Attributes', path: '/admin/attributes' },
                { name: 'Reporting frequencies', path: '/admin/reporting-frequencies' },
                { name: 'Risk types', path: '/admin/risk-types' },
                { name: 'Risk discussion forums', path: '/admin/risk-discussion-forums' },
                { name: 'Units', path: '/admin/units' },
                { name: 'Thresholds', path: '/admin/thresholds' },
                { name: 'Threshold appetites', path: '/admin/threshold-appetites' }
            ].map(NamePathToNavLink);

            links.push({
                key: 'organisation',
                name: 'Organisation',
                url: null,
                isExpanded: organisationExpanded,
                onClick: () => setOrganisationExpanded(!organisationExpanded),
                links: organisationLinks
            });

            links.push({
                key: 'manageusers',
                name: 'Manage Users',
                url: null,
                isExpanded: manageusersExpanded,
                onClick: () => setManageUsersExpanded(!manageusersExpanded),
                links: manageUsersLinks
            });

            links.push({
                key: 'settings',
                name: 'Settings',
                url: null,
                isExpanded: settingsExpanded,
                onClick: () => setSettingsExpanded(!settingsExpanded),
                links: settingsLinks
            });
        }

        setNavs(links);
    }, [up, history, organisationExpanded, manageusersExpanded, settingsExpanded, onLinkClicked, resetErrors, orbConfig]);

    // Automatically expand/collapse sections based on current pathname
    useEffect(() => {
        // This hook will expand the parent menu based on the current URL pathname
        if (
            pathname.startsWith('/admin/users') ||
            pathname.startsWith('/admin/user-groups') ||
            pathname.startsWith('/admin/administrators')
        ) {
            setManageUsersExpanded(true);
        } else {
            setManageUsersExpanded(false);
        }

        if (
            pathname.startsWith('/admin/groups') ||
            pathname.startsWith('/admin/directorates') ||
            pathname.startsWith('/admin/divisions')
        ) {
            setOrganisationExpanded(true);
        } else {
            setOrganisationExpanded(false);
        }

        if (
            pathname.startsWith('/admin/benefit-types') ||
            pathname.startsWith('/admin/attributes') ||
            pathname.startsWith('/admin/reporting-frequencies') ||
            pathname.startsWith('/admin/risk-types') ||
            pathname.startsWith('/admin/risk-discussion-forums') ||
            pathname.startsWith('/admin/units') ||
            pathname.startsWith('/admin/thresholds') ||
            pathname.startsWith('/admin/threshold-appetites')
        ) {
            setSettingsExpanded(true);
        } else {
            setSettingsExpanded(false);
        }
    }, [pathname]);


    return (
        <ThemeProvider theme={darkTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                selectedKey={pathname}
                groups={[{ links: navs }]}
            />
        </ThemeProvider>
    );
};
