import {
    AccountService,
    AttributeService,
    OrgConfigService,
    AttributeTypeService,
    BenefitService,
    BenefitTypeService,
    BenefitUpdateService,
    CommitmentService,
    CommitmentUpdateService,
    ContributorService,
    CorporateRiskMitigationActionService,
    CorporateRiskMitigationActionUpdateService,
    CorporateRiskRiskMitigationActionService,
    CorporateRiskService,
    CorporateRiskUpdateService,
    DepartmentalObjectiveService,
    DependencyService,
    DependencyUpdateService,
    DirectorateService,
    DirectorateUpdateService,
    EntityStatusService,
    FinancialRiskMitigationActionService,
    FinancialRiskMitigationActionUpdateService,
    FinancialRiskService,
    FinancialRiskUpdateService,
    FinancialRiskUserGroupService,
    OrgLevel1Service,
    HealthCheckService,
    KeyWorkAreaService,
    KeyWorkAreaUpdateService,
    ListService,
    MeasurementUnitService,
    MetricService,
    MetricUpdateService,
    MilestoneService,
    MilestoneTypeService,
    MilestoneUpdateService,
    PartnerOrganisationRiskMitigationActionService,
    PartnerOrganisationRiskMitigationActionUpdateService,
    PartnerOrganisationRiskRiskTypeService,
    PartnerOrganisationRiskService,
    PartnerOrganisationRiskUpdateService,
    PartnerOrganisationService,
    PartnerOrganisationUpdateService,
    ProjectBusinessCaseTypeService,
    ProjectPhaseService,
    ProjectStageService,
    ProjectService,
    ProjectUpdateService,
    ReportBuilderService,
    ReportDueDatesService,
    ReportingEntityTypeService,
    ReportingFrequencyService,
    RiskAppetiteService,
    RiskDiscussionForumService,
    RiskImpactLevelService,
    RiskPermissionsService,
    RiskProbabilityService,
    RiskRiskTypeService,
    RiskTypeService,
    RoleService,
    SignOffService,
    ThresholdAppetiteService,
    ThresholdService,
    UserDirectorateService,
    UserGroupService,
    UserPartnerOrganisationService,
    UserProjectService,
    UserRoleService,
    UserService,
    WorkStreamService,
    WorkStreamUpdateService,
    OrgLevel3Service,
    PortfolioService,
    PortfolioUpdateService,
    ProgrammeService,
    ProgrammeStageService,
    ProgrammeUpdateService,
    ProgrammePhaseService,
    App03WorkflowService,
    App03WorkflowStageService,
    App03WorkflowUserService,
    App03TaskService,
    App03TaskUserService,
    GIAADefFormService,
    NAODefFormService,
    CLDefFormService,
    ZFileService,
    ZGraphDefnService,
    ZCoreDashboardService,
    GoDefFormService,
    IAPDefFormService,
    CADefFormService,
    UserAppLaunchLogService,
    GIAAAuditReportService,
    NAOPublicationService,
    IAPActionService,
    CLCaseService,
  } from './services';
  
  export const dataServices = {
    healthCheckService: new HealthCheckService(),
    attributeService: new AttributeService(),
    accountService: new AccountService(),
    attributeTypeService: new AttributeTypeService(),
    orgConfigService: new OrgConfigService(),
    benefitService: new BenefitService(),
    benefitTypeService: new BenefitTypeService(),
    benefitUpdateService: new BenefitUpdateService(),
    commitmentService: new CommitmentService(),
    commitmentUpdateService: new CommitmentUpdateService(),
    contributorService: new ContributorService(),
    corporateRiskMitigationActionService: new CorporateRiskMitigationActionService(),
    corporateRiskMitigationActionUpdateService: new CorporateRiskMitigationActionUpdateService(),
    corporateRiskRiskMitigationActionService: new CorporateRiskRiskMitigationActionService(),
    corporateRiskService: new CorporateRiskService(),
    corporateRiskUpdateService: new CorporateRiskUpdateService(),
    departmentalObjectivesService: new DepartmentalObjectiveService(),
    dependencyService: new DependencyService(),
    dependencyUpdateService: new DependencyUpdateService(),
    directorateService: new DirectorateService(),
    directorateUpdateService: new DirectorateUpdateService(),
    entityStatusService: new EntityStatusService(),
    financialRiskMitigationActionService: new FinancialRiskMitigationActionService(),
    financialRiskMitigationActionUpdateService: new FinancialRiskMitigationActionUpdateService(),
    financialRiskService: new FinancialRiskService(),
    financialRiskUpdateService: new FinancialRiskUpdateService(),
    financialRiskUserGroupService: new FinancialRiskUserGroupService(),
    orgLevel1Service: new OrgLevel1Service(),
    keyWorkAreaService: new KeyWorkAreaService(),
    keyWorkAreaUpdateService: new KeyWorkAreaUpdateService(),
    measurementUnitService: new MeasurementUnitService(),
    metricService: new MetricService(),
    metricUpdateService: new MetricUpdateService(),
    milestoneService: new MilestoneService(),
    milestoneTypeService: new MilestoneTypeService(),
    milestoneUpdateService: new MilestoneUpdateService(),
    partnerOrganisationRiskMitigationActionService: new PartnerOrganisationRiskMitigationActionService(),
    partnerOrganisationRiskMitigationActionUpdateService: new PartnerOrganisationRiskMitigationActionUpdateService(),
    partnerOrganisationRiskRiskTypeService: new PartnerOrganisationRiskRiskTypeService(),
    partnerOrganisationRiskService: new PartnerOrganisationRiskService(),
    partnerOrganisationRiskUpdateService: new PartnerOrganisationRiskUpdateService(),
    partnerOrganisationService: new PartnerOrganisationService(),
    partnerOrganisationUpdateService: new PartnerOrganisationUpdateService(),
    projectBusinessCaseTypeService: new ProjectBusinessCaseTypeService(),
    projectPhaseService: new ProjectPhaseService(),
    projectStageService: new ProjectStageService(),
    projectService: new ProjectService(),
    projectUpdateService: new ProjectUpdateService(),
    reportBuilderService: new ReportBuilderService(),
    reportDueDatesService: new ReportDueDatesService(),
    reportingEntityTypeService: new ReportingEntityTypeService(),
    reportingFrequencyService: new ReportingFrequencyService(),
    riskAppetiteService: new RiskAppetiteService(),
    riskDiscussionForumService: new RiskDiscussionForumService(),
    riskImpactLevelService: new RiskImpactLevelService(),
    riskPermissionsService: new RiskPermissionsService(),
    riskProbabilityService: new RiskProbabilityService(),
    riskRiskTypeService: new RiskRiskTypeService(),
    riskTypeService: new RiskTypeService(),
    roleService: new RoleService(),
    signOffService: new SignOffService(),
    thresholdService: new ThresholdService(),
    thresholdAppetiteService: new ThresholdAppetiteService(),
    userDirectorateService: new UserDirectorateService(),
    userGroupService: new UserGroupService(),
    userPartnerOrganisationService: new UserPartnerOrganisationService(),
    userProjectService: new UserProjectService(),
    userRoleService: new UserRoleService(),
    userService: new UserService(),
    workStreamService: new WorkStreamService(),
    workStreamUpdateService: new WorkStreamUpdateService(),
    orgLevel3Service: new OrgLevel3Service(),
    portfolioService: new PortfolioService(),
    portfolioUpdateService: new PortfolioUpdateService(),
    programmeService: new ProgrammeService(),
    programmeStageService: new ProgrammeStageService(),
    programmeUpdateService: new ProgrammeUpdateService(),
    programmePhaseService: new ProgrammePhaseService(),
    app03WorkflowService: new App03WorkflowService(),
    app03WorkflowUserService: new App03WorkflowUserService(),
    app03WorkflowStageService: new App03WorkflowStageService(),
    app03TaskService: new App03TaskService(),
    app03TaskUserService: new App03TaskUserService(),
    gIAADefFormService: new GIAADefFormService(),
    naoDefFormService: new NAODefFormService(),
    clDefFormService: new CLDefFormService(),
    goDefFormService: new GoDefFormService(),
    iapDefFormService: new IAPDefFormService(),
    caDefFormService: new CADefFormService(),
    userAppLaunchLogService: new UserAppLaunchLogService(),
    zFileService: new ZFileService(),
    zGraphDefnService: new ZGraphDefnService(),
    zCoreDashboardService: new ZCoreDashboardService(),
    gIAAAuditReportService: new GIAAAuditReportService(),
    nAOPublicationService: new NAOPublicationService(),
    iAPActionService: new IAPActionService(),
    cLCaseService: new CLCaseService(),
  };
  